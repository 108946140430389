import React from 'react'
import { Link } from 'gatsby'

const Header = ({ siteTitle }) => (
  <div
    style={{
      marginBottom: '1.45rem',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '2.5rem 1.0875rem',
      }}
    >
      <h1 style={{
        margin: 'auto',
        verticalAlign: 'middle',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      }}>
        <Link
          to="/"
          style={{
            color: '#AF3709',
            fontFamily: 'wickedMouse',
            fontSize: '50px',
            textShadow: '3px 3px #fff, 6px 6px #aaa',
            textDecoration: 'none',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </div>
)

export default Header
